.media-list-item {
  width: 80%;
  margin: 2rem auto;
}
.media-list-item-img-container {
  position: relative;
}

.media-list-item-img-container img {
  max-width: 320px;
  width: 100%;
}

.media-list-item-img-container i {
  font-size: 2rem;
  color: #d50d1b;
  background-color: white;
  border-radius: 25px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.media-list-item input {
  width: 100px;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

.media-type-type {
  font-size: 2.5rem;
  font-weight: bold;
}

.media-list-item input::-webkit-outer-spin-button,
.media-list-item input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.media-list-item input[type='number'] {
  -moz-appearance: textfield;
}
