body {
  color: #333;
  font-family: 'Open Sans', sans-serif;
}

a:not(.nav-link) {
  color: #333 !important;
}

.nav-tabs .nav-item a {
  color: rgba(0, 0, 0, 0.5);
}

.btn {
  border-radius: 0;
}

.btn:active,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary {
  background: #d50d1b;
  border-color: #d50d1b;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background: #a30b15 !important;
  border-color: #a30b15 !important;
}

.btn-primary:disabled {
  background: #eee !important;
  border-color: #eee !important;
  color: #888;
  opacity: 1;
  cursor: not-allowed;
}

.form-control {
  border-radius: 0;
}

.devider {
  border-bottom: 2px solid #d50d1b;
}

.underline {
  position: relative;
}

.underline::before {
  content: '';
  display: block;
  height: 3px;
  background: #d50d1b;
  width: 40px;
  position: absolute;
  bottom: -1px;
  z-index: -1;
}

.bold {
  font-weight: bold;
}
