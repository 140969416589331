.login-view {
  min-height: 100vh;
}

.login-form-wrapper {
  padding: 40px 75px;
  background: #fafafa;
  max-width: calc(100% - 20px);
}

.login-form-wrapper img {
  margin-bottom: 2rem;
  width: 100%;
}

.login-form-wrapper h1 {
  font-size: 20px;
}

.login-form-wrapper h2 {
  font-size: 1rem;
}
